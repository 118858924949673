import APIService from "../APIService";

export default class EmailContaService extends APIService {
  async store(CodUsu, data) {
    try {
      return await this.send("post", "usuario/" + CodUsu + "/email-conta", data);
    }
    catch (e) {
      return false;
    }
  }

  async all(CodUsu, options) {
    try {
      const response = await this.send("get", "usuario/" + CodUsu + "/email-conta" + (options ? (this.serverSideOptionsFormat(options) == "" ? "?" : this.serverSideOptionsFormat(options)) : ""));
      return response;
    }
    catch (e) {
      return false;
    }
  }
  
  async update(CodUsu, data) {
    try {
      return await this.send("put", "usuario/" + CodUsu + "/email-conta/" + data.CODEMC, data);
    }
    catch (e) {
      return false;
    }
  }
  
  async delete(CodUsu, CodEmc) {
    try {
      return await this.send("delete", "usuario/" + CodUsu + "/email-conta/" + CodEmc);
    }
    catch (e) {
      return false;
    }
  }
}