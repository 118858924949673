<script setup>
import { ref, onMounted } from "vue"
import { useToast } from "primevue/usetoast"

import UsuarioService from "../../../../service/administracao/UsuarioService";
import EmailContaService from "../../../../service/email/EmailContaService";

const usuarioService = new UsuarioService();
const emailContaService = new EmailContaService();

const toast = useToast();

const contas = ref([]);
const usuarios = ref([]);
const usuario = ref();
const conta = ref();

const totalRecords = ref();
const loading = ref(false);
const contaDialog = ref(false);
const deleteDialog = ref(false);

const open = (data) => {
  contaDialog.value = true;
  conta.value = data;
}

const confirmDelete = (data) => {
  deleteDialog.value = true;
  conta.value = data;
}

const openNew = () => {
  contaDialog.value = true;
  conta.value = {};
}

const validate = () => {
  if (!conta.value.EMAEMC) {
    toast.add({severity:'warn', summary: 'E-mail', detail: 'Informe a conta', life: 3000});
    return false;
  }
  
  if (!conta.value.USUEMC) {
    toast.add({severity:'warn', summary: 'E-mail', detail: 'Informe o usuário', life: 3000});
    return false;
  }
  
  if (!conta.value.password) {
    toast.add({severity:'warn', summary: 'E-mail', detail: 'Informe a senha', life: 3000});
    return false;
  }
  
  if (!conta.value.HOSSAIEMC) {
    toast.add({severity:'warn', summary: 'E-mail', detail: 'Informe o servidor SMTP', life: 3000});
    return false;
  }
  
  if (!conta.value.PORSAIEMC) {
    toast.add({severity:'warn', summary: 'E-mail', detail: 'Informe a porta SMTP', life: 3000});
    return false;
  }

  return true;
}

const save = async () => {
  if (!validate()) return;

  contaDialog.value = false;

  toast.add({severity:'info', summary: 'E-mail', detail: 'Salvando...', life: 3000});

  let response;
  if (conta.value.CODEMC) {
    response = await emailContaService.update(usuario.value.CODUSU, conta.value);
  }
  else {
    response = await emailContaService.store(usuario.value.CODUSU, conta.value);
  }

  if (response) {
    toast.add({severity: 'success', summary: 'E-mail', detail: 'Conta salva com sucesso', life: 3000});
  }
  else {
    toast.add({severity: 'warn', summary: 'E-mail', detail: 'Ocorreu um erro ao salvar', life: 3000});
  }

  load();
}

const remove = async () => {
  deleteDialog.value = false;

  toast.add({severity:'info', summary: 'E-mail', detail: 'Excluindo...', life: 3000});

  await emailContaService.delete(usuario.value.CODUSU, conta.value.CODEMC);

  toast.add({severity:'success', summary: 'E-mail', detail: 'Conta excluída', life: 3000});

  load();
}

const load = (options) => {
  if (!options) {
    options = {};
  }
  loading.value = true;

  emailContaService.all(usuario.value.CODUSU, options)
  .then(
    response => {
      contas.value = response.data;
      totalRecords.value = response.totalRecords;
    }
  )
  .finally(() => {
    loading.value = false
  });
}

const onPage = (event) => load(event);
const onSort = (event) => load(event);

onMounted(() => {
  usuarioService.all({
    query : {
      serverSide : false
    }
  })
  .then(
    response => usuarios.value = response
  );
})
</script>
<template>
  <div class="p-fluid">
    <div class="grid">
      <div class="col-12">        
        <div class="card">
          <h4>
            <Translate text="E-mail Conta" />
          </h4>

          <div class="field col-12 sm:col-6 md:col-6 lg:col-6">
            <label for="name">Usuário</label>
            <Dropdown
              v-model="usuario"
              :options="usuarios"
              @change="load"
              filter
              optionLabel="NOMUSU" 
              placeholder="Selecione">
              <template #value="slotProps">
                <div v-if="slotProps.value && slotProps.value.CODUSU">
                  <span :class="'product-badge status-' + slotProps.value.value">{{ slotProps.value.NOMUSU }}</span>
                </div>
                <span v-else>
                  {{ slotProps.placeholder }}
                </span>
              </template>
            </Dropdown>
          </div>

          <DataTable 
            :value="contas" 
            :paginator="true"
            :rows="10"            
            :loading="loading"
            :lazy="true"
            :totalRecords="totalRecords"
            @page="onPage($event)"
            @sort="onSort($event)"
            responsiveLayout="scroll" 
            dataKey="CODEMC" v-if="usuario">
            <template #header>              
              <div class="formgrid">                
                <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center md:col-12">
                  <div class="col-2">
                    <Button label="Novo" icon="pi pi-plus" class="p-button-success" @click="openNew" />
                  </div>
                  <div class="col-10">
                    <span class="block mt-2 md:mt-0 p-input-icon-left">
                      <i class="pi pi-search" />
                      <InputText v-model="src" placeholder="Procurar..." @input="search" />
                    </span>
                  </div>
                </div>
              </div>            
            </template>

            <template #empty v-if="!loading">
              Nenhum registro encontrado
            </template>
            <template #loading>
              <Skeleton></Skeleton>
            </template>
            <template #footer v-if="!loading">
              Total de registros {{ totalRecords }}
            </template>

            <div v-if="loading">
              <Column header="Código" style="min-width:12rem">
                <template #body>
                  <Skeleton style="height: 2rem;" class="mt-2" />
                </template>
              </Column>
              <Column header="Descrição" style="min-width:12rem">
                <template #body>
                  <Skeleton style="height: 2rem;" class="mt-2" />
                </template>
              </Column>
              <Column headerStyle="min-width:10rem; width:10rem;">
                <template #body>
                  <div class="flex align-items-center gap-2 mt-2">
                    <Skeleton shape="circle" style="height: 2.5rem; width: 2.5rem;" />
                    <Skeleton shape="circle" style="height: 2.5rem; width: 2.5rem;" />
                  </div>
                </template>
              </Column>
            </div>
            <div v-else>
              <Column field="CODDFC" header="Código" :sortable="true" style="min-width:2rem">
                <template v-if="!loading" #body="{ data }">
                  {{ data.CODEMC }}
                </template>
              </Column>
              <Column field="DESDFC" header="Descrição" :sortable="true">
                <template #body="{ data }">
                  {{ data.EMAEMC }}
                </template>
              </Column>
              <Column headerStyle="min-width:10rem; width:10rem;">
                <template #body="props">
                  <Button icon="pi pi-pencil" v-tooltip.top="'Editar'" class="p-button-rounded p-button-success mr-2"
                    @click="open(props.data)" />
                  <Button icon="pi pi-trash" v-tooltip.top="'Excluir'" class="p-button-rounded p-button-warning mt-2"
                    @click="confirmDelete(props.data)" />
                </template>
              </Column>
            </div>
          </DataTable>
          <Message v-else>Selecione um usuário</Message>
        </div>
      </div>
    </div>
  </div>
  <Dialog v-model:visible="contaDialog" :style="{width: '70%'}" :modal="true" class="p-fluid" maximizable>
    <div class="formgrid grid" v-if="usuario">
      <div class="field col-12">
        <h3>{{ usuario.NOMUSU }}</h3>
      </div>
      <div class="field col-12">
        <label for="name"><Translate text="Conta" /> *</label>
        <InputText v-model="conta.EMAEMC" required="true" autofocus :class="{'p-invalid': submitted && !entidade.NOMENT}" />
      </div>
      <div class="field col-12">
        <label for="name"><Translate text="Máscara" /></label>
        <InputText v-model="conta.REPEMC" v-tooltip="'A resposta do recebedor será enviada para este e-mail'" />
      </div>
      <div class="field col-12">
        <TabView>          
          <TabPanel header="Credenciais">
            <div class="formgrid grid">
              <div class="field col-12">
                <label for="name"><Translate text="Usuário" /> *</label>
                <InputText v-model="conta.USUEMC" required="true" autofocus :class="{'p-invalid': submitted && !entidade.NOMENT}" />
              </div>
              <div class="field col-6">
                <label for="name"><Translate text="Senha" /> *</label>
                <InputText v-model="conta.password" required="true" autofocus :class="{'p-invalid': submitted && !entidade.NOMENT}" />
              </div>              
            </div>
          </TabPanel>
          <TabPanel header="Envio">
            <div class="formgrid grid">
              <div class="field col-10">
                <label for="name"><Translate text="Servidor" /> *</label>
                <InputText v-model="conta.HOSSAIEMC" required="true" autofocus :class="{'p-invalid': submitted && !entidade.NOMENT}" v-tooltip="'Servidor SMTP'" />
              </div>
              <div class="field col-2">
                <label for="name"><Translate text="Porta" />*</label>
                <InputNumber v-model="conta.PORSAIEMC" required="true" autofocus :class="{'p-invalid': submitted && !entidade.NOMENT}" />
              </div>
            </div>
          </TabPanel>
          <TabPanel header="Recebimento">
            <div class="formgrid grid">
              <div class="field col-10">
                <label for="name"><Translate text="Servidor" /></label>
                <InputText v-model="conta.HOSENTEMC" required="true" autofocus v-tooltip="'Servidor POP, POP3 ou IMAP'" />
              </div>
              <div class="field col-2">
                <label for="name"><Translate text="Porta" /></label>
                <InputNumber v-model="conta.PORENTEMC" required="true" autofocus />
              </div>
            </div>
          </TabPanel>
        </TabView>
      </div>
    </div>
    <template #footer>
      <Button label="Fechar" icon="pi pi-times" class="p-button-text" @click="contaDialog = false"/>
      <Button label="Salvar" icon="pi pi-check" class="p-button-text" @click="save" />
    </template>        
  </Dialog>
  <Dialog
    v-model:visible="deleteDialog"
    :style="{ width: '450px' }"
    header="Confirmação de exclusão"
    :modal="true">
    <div class="flex align-items-center justify-content-center">
      <div class="formgrid grid">
        <div class="field col-12">
          <h3>{{ conta.EMAEMC }}</h3>
        </div>
        <div class="field col-12">
          <span>Deseja realmente excluir esta conta?</span>
        </div>
      </div>    
    </div>
    <template #footer>
      <Button
        label="Não"
        icon="pi pi-times"
        class="p-button-text"
        @click="deleteDialog = false" />
      <Button
        label="Sim"
        icon="pi pi-check"
        class="p-button-text"
        @click="remove" />
    </template>
  </Dialog>
  <Toast />
</template>